import { FormBlock, FormButton, FormInput } from "components";
import DatepickerComponent from "components/datePicker/datePickerComponent";
import InputRow from "components/InputRow";
import LockIcon from "components/LockIcon";
import { CUSTOM_STYLE, RESPONSIVE } from "constant";
import { format } from "currency-formatter";
import _ from "lodash";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import Layout from "../../../components/Layout";
import AutoCompleteAddress from "../../Common/AutoCompleteAddress";
import "./ProfessionIncome.css";

export default function ProfessionIncomeScreen({
  stepperOptions,
  onNextStep,
  onBackStep,
}) {
  const {
    trigger,
    watch,
    formState: { errors, touchedFields },
    getValues,
    control,
    setValue,
  } = useFormContext();
  const { lands = [] } = watch();
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });

  // Validate and go to next page
  const _onNextPage = async () => {
    const isValid = await stepperOptions.triggerForm(3);
    if (isValid) {
      onNextStep();
    }
  };

  // Validate and go to previous page
  const _onBackStep = async () => {
    await stepperOptions.triggerForm(3);
    onBackStep();
  };

  // Scroll view to top
  useEffect(() => {
    document
      .getElementsByClassName(
        `${isMobile ? "MuiStepLabel-active" : "active"}`
      )[0]
      .scrollIntoView({ behavior: "smooth", inline: "center" });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout
        stepperOptions={stepperOptions}
        onNextStep={_onNextPage}
        onBackStep={_onBackStep}
        footer={{ icon: <LockIcon /> }}
      >
        <div className="pt-0" />
        <FormBlock
          title={
            <FormattedMessage
              id="income.title.yourEmployer"
              /* defaultMessage="Ihr Arbeitgeber" */
            />
          }
        >
          <Controller
            name="arbeitgeber"
            control={control}
            defaultValue={getValues("arbeitgeber")}
            render={({ field }) => {
              return (
                <FormInput
                  isValid={
                    (watch("anstellungsverhaeltnis") ===
                    "Ohne Anstellungsverhältnis"
                      ? true
                      : watch("arbeitgeber")) && !errors.arbeitgeber
                  }
                  isRequired
                  label={
                    <FormattedMessage
                      id="income.input.company"
                      /* defaultMessage="Firma" */
                    />
                  }
                  {...field}
                  // errorMessage={errors.arbeitgeber?.message && errors.arbeitgeber?.message}
                  isShowError={!!errors.arbeitgeber?.message}
                />
              );
            }}
          />

          <InputRow
            label={
              <FormattedMessage
                id="income.input.companyStreet"
                /* defaultMessage="Strasse/Nr" */
              />
            }
            isRequired
            isValid={
              (watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis"
                ? true
                : watch("strasseArbeitgeber")) &&
              !errors.strasseArbeitgeber &&
              !errors.hausnummerArbeitgeber
              // isClickNext && ( isValidAuto ||
              // ((watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis"
              //   ? true
              //   : watch("strasseArbeitgeber")) &&
              //   !errors.strasseArbeitgeber &&
              //   !errors.hausnummerArbeitgeber))
            }
            isShowError={
              errors.strasseArbeitgeber
                ? errors.strasseArbeitgeber.message
                : errors.hausnummerArbeitgeber
                ? errors.hausnummerArbeitgeber.message
                : null
              // isClickNext &&
              // (!isValidAuto ||
              //   (errors.strasseArbeitgeber
              //     ? errors.strasseArbeitgeber.message
              //     : errors.hausnummerArbeitgeber
              //     ? errors.hausnummerArbeitgeber.message
              //     : null))
            }
          >
            <Row>
              <Col md={8} sm={8} xs={8}>
                <AutoCompleteAddress
                  value={watch("strasseArbeitgeber")}
                  fields={{
                    street: "strasseArbeitgeber",
                    houseNumber: "hausnummerArbeitgeber",
                    swissZipCode: "PLZArbeitgeber",
                    town: "ortArbeitgeber",
                  }}
                  setValueInput={setValue}
                  trigger={trigger}
                />
              </Col>
              <Col md={4} sm={4} xs={4}>
                <Controller
                  name="hausnummerArbeitgeber"
                  control={control}
                  defaultValue={getValues("hausnummerArbeitgeber")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        maxLength="6"
                        required
                        {...field}
                        onChange={(event) => {
                          setValue("hausnummerArbeitgeber", event.target.value);
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            {/* <Row>
            <Col md={12} className="text-danger">{errors.strasseArbeitgeber ? errors.strasseArbeitgeber.message : errors.hausnummerArbeitgeber ? errors.hausnummerArbeitgeber.message : null}</Col>
          </Row> */}
          </InputRow>
          <InputRow
            label={
              <FormattedMessage
                id="income.input.companyPostcode"
                /* defaultMessage="PLZ/Ort" */
              />
            }
            isRequired
            isValid={
              (watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis"
                ? true
                : watch("PLZArbeitgeber") && watch("ortArbeitgeber")) &&
              !errors.PLZArbeitgeber &&
              !errors.ortArbeitgeber
              // isClickNext &&
              // (isValidAuto ||
              //   ((watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis"
              //     ? true
              //     : watch("PLZArbeitgeber") && watch("ortArbeitgeber")) &&
              //     !errors.PLZArbeitgeber &&
              //     !errors.ortArbeitgeber))
            }
            isShowError={
              errors.PLZArbeitgeber
                ? errors.PLZArbeitgeber.message
                : errors.ortArbeitgeber
                ? errors.ortArbeitgeber.message
                : null
              // isClickNext &&
              // (!isValidAuto ||
              //   (errors.PLZArbeitgeber
              //     ? errors.PLZArbeitgeber.message
              //     : errors.ortArbeitgeber
              //     ? errors.ortArbeitgeber.message
              //     : null))
            }
          >
            <Row>
              <Col md={4} xs={4} sm={4}>
                <Controller
                  name="PLZArbeitgeber"
                  control={control}
                  defaultValue={getValues("PLZArbeitgeber")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        inputMode="numeric"
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          event.preventDefault();
                          if (!_.isNaN(Number(event.target.value))) {
                            setValue("PLZArbeitgeber", event.target.value);
                          }
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md={8} xs={8} sm={8}>
                <Controller
                  name="ortArbeitgeber"
                  control={control}
                  defaultValue={getValues("ortArbeitgeber")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                        onBlur={async () => {
                          field.onBlur();
                          // if (isBlurActive && (!_.isEmpty(watch("strasseArbeitgeber")) && !_.isEmpty(watch("hausnummerArbeitgeber")) && !_.isEmpty(watch("PLZArbeitgeber")) && !_.isEmpty(watch("ortArbeitgeber")))) {
                          //   const { data } = await autoComplete(
                          //     `${watch("strasseArbeitgeber") || ""} ${watch("hausnummerArbeitgeber") || ""} ${watch(
                          //       "PLZArbeitgeber"
                          //     ) || ""} ${watch("ortArbeitgeber") || ""}`,
                          //     getValues("landArbeitgeber")?.value.toLowerCase()
                          //   );
                          //   setAutoCompleteData(data);
                          //   setAutoCompleteModalOpen(true);
                          // }
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            {/* <Row>
            <Col md={12} className="text-danger">{errors.PLZArbeitgeber ? errors.PLZArbeitgeber.message : errors.ortArbeitgeber ? errors.ortArbeitgeber.message : null}</Col>
          </Row> */}
          </InputRow>

          <Controller
            name="landArbeitgeber"
            isClearable
            control={control}
            render={({ field }) => (
              <InputRow
                isShowError={
                  errors.landArbeitgeber && errors.landArbeitgeber.message
                }
                isValid={field.value}
                label={
                  <FormattedMessage
                    id="income.input.companyCountry"
                    /* defaultMessage="Land" */
                  />
                }
                isRequired
              >
                <Select
                  placeholder={<FormattedMessage id="common.date.year" />}
                  {...field}
                  isSearchable
                  options={lands.map((e) =>
                    e.value === ""
                      ? { value: e.value, label: e.label, isDisabled: true }
                      : e
                  )}
                  styles={CUSTOM_STYLE}
                />
              </InputRow>
            )}
          />

          <Controller
            name="anstellungsverhaeltnis"
            control={control}
            defaultValue={getValues("anstellungsverhaeltnis")}
            render={({ field }) => {
              return (
                <FormButton
                  moreSpace
                  isShowError={!!errors["anstellungsverhaeltnis"]}
                  isRequired
                  isValid={field.value}
                  label={
                    <FormattedMessage
                      id="income.input.employment"
                      /* defaultMessage="Anstellungsverhältnis" */
                    />
                  }
                  buttons={[
                    {
                      icon: "icon-open-end",
                      label: (
                        <FormattedMessage
                          id="income.icon.indefinite"
                          /* defaultMessage="Unbefristet & ungekündigt" */
                        />
                      ),
                      value: "unbefristet & ungekündigt",
                    },
                    {
                      icon: "icon-update",
                      label: (
                        <FormattedMessage
                          id="income.icon.temporary"
                          /* defaultMessage="Temporär" */
                        />
                      ),
                      value: "temporär",
                    },
                    {
                      icon: "icon-unemployed",
                      label: (
                        <FormattedMessage
                          id="income.icon.unemployed"
                          /* defaultMessage="Ohne Anstelle" */
                        />
                      ),
                      value: "Ohne Anstellungsverhältnis",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);

                    if (value === "unbefristet & ungekündigt") {
                      setValue("angestelltBis", "");
                      setValue("vermoegen", 0);
                    } else if (value === "Ohne Anstellungsverhältnis") {
                      setValue("angestelltBis", "");
                      setValue("angestelltSeit", "");
                    } else {
                      setValue("vermoegen", 0);
                    }
                  }}
                  {...field}
                />
              );
            }}
          />

          {watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis" && (
            <Controller
              name="vermoegen"
              control={control}
              defaultValue={""}
              render={({ field }) => {
                return (
                  <InputRow
                    label={formatMessage({
                      id: "income.input.assets",
                      /* defaultMessage: "Vermögen", */
                    })}
                    isShowError={!!errors["vermoegen"]}
                    isValid={touchedFields["vermoegen"] && !errors["vermoegen"]}
                    isRequired
                  >
                    <Row className="d-flex align-items-center">
                      <Col md={8}>
                        <Form.Control
                          data-cy="vermoegen"
                          inputMode="numeric"
                          className="input-shadow"
                          required
                          {...field}
                          onChange={(event) => {
                            event.preventDefault();
                            let valueInput = event.target.value
                              .split("‘")
                              .join("")
                              .trim();
                            // if (valueInput === "" || valueInput === "00" || valueInput === "0") {
                            //   valueInput = 0
                            // }
                            if (!_.isNaN(Number(valueInput))) {
                              setValue("vermoegen", valueInput);
                            }
                          }}
                          value={
                            field.value
                              ? format(field.value, {
                                  thousand: "‘",
                                  precision: 0,
                                })
                              : field.value
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <p className="text-secondary align-middle p-0 m-0">
                          <FormattedMessage
                            id="common.text.money"
                            /* defaultMessage="CHF" */
                          />
                        </p>
                      </Col>
                    </Row>
                  </InputRow>
                );
              }}
            />
          )}
          {watch("anstellungsverhaeltnis") !== "Ohne Anstellungsverhältnis" && (
            <Controller
              name="angestelltSeit"
              control={control}
              defaultValue={getValues("angestelltSeit")}
              render={({ field: { onChange, onBlur, value }, fieldState }) => {
                return (
                  <InputRow
                    isShowError={!!errors.angestelltSeit}
                    label={formatMessage({
                      id: "income.input.employedSince",
                      /* defaultMessage: "Angestellt seit", */
                    })}
                    isRequired
                    isValid={
                      watch("angestelltSeit") &&
                      fieldState.isTouched &&
                      fieldState.isDirty &&
                      fieldState.error === undefined &&
                      !fieldState.invalid
                    }
                    tooltip={
                      <FormattedMessage
                        id="common.date.format"
                        /* defaultMessage="z.B.15.12.1980" */
                      />
                    }
                  >
                    <Row className="d-flex align-items-center">
                      <Col md={4}>
                        <DatepickerComponent
                          //value={value}
                          value={value ? new Date(value) : value}
                          onChange={(e) => {
                            onChange(e);
                            onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                          }}
                          onBlur={onBlur}
                        />
                      </Col>
                      {/* <Col md={12} className="text-danger"> {errors.angestelltSeit && errors.angestelltSeit.message}</Col> */}
                    </Row>
                  </InputRow>
                );
              }}
            />
          )}
          {(watch("anstellungsverhaeltnis") === "temporär" ||
            watch("anstellungsverhaeltnis") === "befristet") && (
            <Controller
              name="angestelltBis"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState }) => {
                return (
                  <>
                    <InputRow
                      label={formatMessage({
                        id: "income.input.limitedUntil",
                        /* defaultMessage: "Befristet bis", */
                      })}
                      isRequired
                      isValid={
                        watch("angestelltBis") &&
                        fieldState.isTouched &&
                        fieldState.isDirty &&
                        fieldState.error === undefined &&
                        !fieldState.invalid
                      }
                      tooltip={
                        <FormattedMessage
                          id="income.date.format.Befristet"
                          /* defaultMessage="z.B.15.12.1980" */
                        />
                      }
                      isShowError={!!errors.angestelltBis}
                    >
                      <div className="row d-flex align-items-center">
                        <Col md={4}>
                          <DatepickerComponent
                            isMinDateNextDay
                            //value={value}
                            value={value ? new Date(value) : value}
                            onChange={(e) => {
                              onChange(e);
                              onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                            }}
                            onBlur={onBlur}
                          />
                        </Col>
                        {/* <Col md={12} className="text-danger"> {errors.angestelltBis && errors.angestelltBis.message}</Col> */}
                      </div>
                    </InputRow>
                  </>
                );
              }}
            />
          )}
        </FormBlock>
        <FormBlock
          title={
            <FormattedMessage
              id="income.title.primaryIncome"
              /* defaultMessage="Ihr Haupteinkommen" */
            />
          }
        >
          <Controller
            name="monatlichesNettoEinkommen"
            control={control}
            render={({ field }) => {
              return (
                <InputRow
                  label={
                    <FormattedMessage
                      id="income.input.income"
                      /* defaultMessage="Monatliches Nettoeinkommen" */
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="income.input.income.tooltip"
                      /* defaultMessage="Monatliches Nettoeinkommen" */
                    />
                  }
                  isShowError={!!errors["monatlichesNettoEinkommen"]}
                  isValid={
                    watch("anstellungsverhaeltnis") ===
                      "Ohne Anstellungsverhältnis" ||
                    (touchedFields["monatlichesNettoEinkommen"] &&
                      !errors["monatlichesNettoEinkommen"])
                  }
                  isRequired
                >
                  <Row className="d-flex align-items-center">
                    <Col md={8}>
                      <Form.Control
                        data-cy="monatlichesNettoEinkommen"
                        inputMode="numeric"
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          event.preventDefault();
                          let valueInput = event.target.value
                            .split("‘")
                            .join("")
                            .trim();
                          // if (valueInput === "" || valueInput === "00" || valueInput === "0") {
                          //   valueInput = 0
                          // }
                          if (!_.isNaN(Number(valueInput))) {
                            setValue("monatlichesNettoEinkommen", valueInput);
                          }
                        }}
                        value={
                          field.value
                            ? format(field.value, {
                                thousand: "‘",
                                precision: 0,
                              })
                            : field.value
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <p className="text-secondary align-middle p-0 m-0">
                        <FormattedMessage
                          id="common.text.monthlymoney"
                          /* defaultMessage="CHF" */
                        />
                      </p>
                    </Col>
                  </Row>
                </InputRow>
              );
            }}
          />

          <Controller
            name="dreizehnterMonatslohn"
            control={control}
            defaultValue={getValues("dreizehnterMonatslohn")}
            render={({ field }) => {
              return (
                <FormButton
                  isLeft
                  isRequired
                  isShowError={!!errors["dreizehnterMonatslohn"]}
                  isValid={
                    watch("anstellungsverhaeltnis") ===
                    "Ohne Anstellungsverhältnis"
                      ? true
                      : field.value
                      ? true
                      : false
                  }
                  label={
                    <FormattedMessage
                      id="income.button.additionalMonthPayment"
                      /* defaultMessage="Erhalten Sie einen 13. Monatslohn?" */
                    />
                  }
                  buttons={[
                    {
                      text: (
                        <FormattedMessage
                          id="income.button.yes"
                          /* defaultMessage="Ja" */
                        />
                      ),
                      value: "Ja",
                    },
                    {
                      text: (
                        <FormattedMessage
                          id="income.button.no"
                          /* defaultMessage="Nein" */
                        />
                      ),
                      value: "Nein",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                  }}
                  {...field}
                />
              );
            }}
          />

          <Controller
            name="gratifikation"
            control={control}
            defaultValue={getValues("gratifikation")}
            render={({ field }) => {
              return (
                <FormButton
                  isLeft
                  tooltip={
                    <FormattedMessage
                      id="income.button.bonusGratuity.tooltip"
                      /* defaultMessage="Monatliches Nettoeinkommen" */
                    />
                  }
                  isShowError={
                    !!errors["gratifikation"] ||
                    !!errors["durchschnittGratifikation"]
                  }
                  isRequired
                  isValid={
                    watch("anstellungsverhaeltnis") ===
                      "Ohne Anstellungsverhältnis" ||
                    (watch("gratifikation") === "Ja"
                      ? watch("durchschnittGratifikation") !== 0 &&
                        touchedFields["durchschnittGratifikation"] &&
                        watch("durchschnittGratifikation") !== ""
                        ? true
                        : false
                      : watch("gratifikation") === "Nein"
                      ? true
                      : false)
                  }
                  label={
                    <FormattedMessage
                      id="income.button.bonus"
                      /* defaultMessage="Erhalten Sie einen 13 Bonus order eine Gratification" */
                    />
                  }
                  buttons={[
                    {
                      text: (
                        <FormattedMessage
                          id="income.button.yes"
                          /* defaultMessage="Ja" */
                        />
                      ),
                      value: "Ja",
                    },
                    {
                      text: (
                        <FormattedMessage
                          id="income.button.no"
                          /* defaultMessage="Nein" */
                        />
                      ),
                      value: "Nein",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                    if (value === "Nein") {
                      setValue("durchschnittGratifikation", "");
                      // clearErrors("durchschnittGratifikation");
                    }
                  }}
                  {...field}
                >
                  {watch("gratifikation") === "Ja" && (
                    <Row
                      className={`d-flex align-items-center ml-2 ${
                        isMobile ? "w-55" : "w-80"
                      }`}
                    >
                      <div className="sub-field">
                        <Controller
                          name="durchschnittGratifikation"
                          control={control}
                          defaultValue={watch("durchschnittGratifikation")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                inputMode="numeric"
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  event.preventDefault();
                                  let valueInput = event.target.value
                                    .split("‘")
                                    .join("")
                                    .trim();
                                  // if (valueInput === "" || valueInput === "00" || valueInput === "0") {
                                  //   valueInput = 0
                                  // }
                                  if (!_.isNaN(Number(valueInput))) {
                                    setValue(
                                      "durchschnittGratifikation",
                                      valueInput
                                    );
                                  }
                                }}
                                value={
                                  field.value
                                    ? format(field.value, {
                                        thousand: "‘",
                                        precision: 0,
                                      })
                                    : field.value
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <Col md={5} className={`${isMobile ? "p-0" : ""}`}>
                        <p className="text-secondary align-middle p-0 m-0">
                          <FormattedMessage
                            id="common.text.yearlymoney"
                            /* defaultMessage="CHF" */
                            values={{ value: "" }}
                          />
                        </p>
                      </Col>
                    </Row>
                  )}
                </FormButton>
              );
            }}
          />
          <Row>
            <Col md={4} sm={0}></Col>
            {/* <Col md={8} sm={12} className="text-danger" >{errors.durchschnittGratifikation && watch("gratifikation") === "Ja" && errors.durchschnittGratifikation.message}</Col> */}
          </Row>
        </FormBlock>
        <FormBlock
          title={
            <FormattedMessage
              id="income.title.additonalEmployerTitle"
              /* defaultMessage="Nebenerwerb / weiterer Arbeitgeber" */
            />
          }
        >
          <Controller
            name="nebenerwerbChecked"
            control={control}
            defaultValue={getValues("nebenerwerbChecked")}
            render={({ field }) => {
              return (
                <FormButton
                  isLeft
                  isShowError={
                    !!errors["nebenerwerb"] || !!errors["nebenerwerbChecked"]
                  }
                  isRequired
                  isValid={
                    watch("anstellungsverhaeltnis") ===
                      "Ohne Anstellungsverhältnis" ||
                    (watch("nebenerwerbChecked") === "Ja"
                      ? watch("nebenerwerb") !== 0 &&
                        touchedFields["nebenerwerb"] &&
                        watch("nebenerwerb") !== ""
                        ? true
                        : false
                      : watch("nebenerwerbChecked") === "Nein"
                      ? true
                      : false)
                  }
                  label={
                    <FormattedMessage
                      id="income.button.additionalEmployer"
                      /* defaultMessage="Haben Sie einen Nebenerwerb / weiterer Arbeitgeber" */
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="income.button.additionalEmployer.tooltip"
                      /* defaultMessage="Haben Sie einen Nebenerwerb / weiterer Arbeitgeber" */
                    />
                  }
                  buttons={[
                    {
                      text: (
                        <FormattedMessage
                          id="income.button.yes"
                          /* defaultMessage="Ja" */
                        />
                      ),
                      value: "Ja",
                    },
                    {
                      text: (
                        <FormattedMessage
                          id="income.button.no"
                          /* defaultMessage="Nein" */
                        />
                      ),
                      value: "Nein",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                    if (value === "Nein") {
                      setValue("nebenerwerb", "");
                      // clearErrors("nebenerwerb");
                    }
                  }}
                  {...field}
                >
                  {watch("nebenerwerbChecked") === "Ja" && (
                    <Row
                      className={`d-flex align-items-center ml-2 ${
                        isMobile ? "w-55" : "w-80"
                      }`}
                    >
                      <div className="sub-field">
                        <Controller
                          name="nebenerwerb"
                          control={control}
                          defaultValue={watch("nebenerwerb")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                inputMode="numeric"
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  event.preventDefault();
                                  let valueInput = event.target.value
                                    .split("‘")
                                    .join("")
                                    .trim();
                                  // if (valueInput === "" || valueInput === "00" || valueInput === "0") {
                                  //   valueInput = 0
                                  // }
                                  if (!_.isNaN(Number(valueInput))) {
                                    setValue("nebenerwerb", valueInput);
                                  }
                                }}
                                value={
                                  field.value
                                    ? format(field.value, {
                                        thousand: "‘",
                                        precision: 0,
                                      })
                                    : field.value
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <Col md={5} className={`${isMobile ? "p-0" : ""}`}>
                        <p className="text-secondary align-middle p-0 m-0">
                          <FormattedMessage
                            id="common.text.monthlymoney"
                            /* defaultMessage="CHF" */
                            values={{ value: "" }}
                          />
                        </p>
                      </Col>
                    </Row>
                  )}
                </FormButton>
              );
            }}
          />
          <Row>
            <Col md={4} sm={0}></Col>
            {/* <Col md={8} sm={12} className="text-danger" >{errors.nebenerwerb && watch("nebenerwerbChecked") === "Ja" && errors.nebenerwerb.message}</Col> */}
          </Row>
        </FormBlock>
      </Layout>
    </>
  );
}
