import Header from "components/HeaderV2/HeaderV2";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { Image } from "react-bootstrap";
import CarHolderImgSrc from "../../assets/carholder-white.png";
import CarBackgroundImgSrc from "../../assets/bg-car-holder.png";
import GeneralInformation from "./GeneralInformation";
import StickyCta from "components/CTA/StickyCta";
import FooterView from "./Footer";
import PaymentInformation from "./PaymentInformation";
import DurationInformation from "./DurationInformation";
import MileageInformation from "./MileageInformation";
import { getInitialValues, getOEMs } from "utils/api";
import {
  calcEffInterest,
  calcFinalValue,
  generateMailBody,
  getGridSystemForBody,
  getMaxMonthOnMonthChange,
  getOemByMileage,
  getOemValueByMileageAndMonth,
  isCurrResidualEqualsOrGreaterThanMaxResidual as isEqualOrGreater,
  leasingCalculatorDefaultState,
} from "./leasing-engine";
import "./leasingPublic.css";
import ImageComponent from "./ImageComponent";
import { useIntl } from "react-intl";
import useWindowSize from "hooks/useWindowSize";

function LeasingCalculator() {
  // Ref for scrolling effect
  const footerRef = useRef(null);
  const bodyRef = useRef(null);
  const ctaRef = useRef(null);
  const durationViewRef = useRef(null);
  const paymentViewRef = useRef(null);
  const mileageViewRef = useRef(null);

  // State
  const [isOpenModalCta, setIsOpenModalCta] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [availableOEMs, setAvailableOEMs] = useState([]);
  const [leasingCalculatorState, setLeasingCalculatorState] = useState(
    leasingCalculatorDefaultState
  );
  const [interestDataCache, setInterestDataCache] = useState([]);

  // Intl
  const { formatMessage } = useIntl();

  // CSS
  const [width, height] = useWindowSize();

  const isCalculatorAvailable =
    leasingCalculatorState.modelMarke && leasingCalculatorState.modelType;

  const jumpToView = (view) => {
    return view.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    fetchOEMs();
  }, []);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    if (!document.querySelector(".cta2")) return;
    const cta = document.querySelector(".cta2");
    const footer = document.querySelector(".footer2").getBoundingClientRect();
    if (footer.top <= height) {
      isOpenModalCta
        ? cta.classList.add("open-modal")
        : cta.classList.remove("is-sticky");
    }
  }, [isOpenModalCta, height]);

  /* Method that will fix CTA after a specific scrollable */
  const isSticky = () => {
    if (!document.querySelector(".cta2") || !document.querySelector(".footer2"))
      return;

    const cta = document.querySelector(".cta2");
    const footer = document.querySelector(".footer2").getBoundingClientRect();
    const footerTop = footer.top;

    footerTop >= height
      ? cta.classList.add("is-sticky")
      : cta.classList.remove("is-sticky");
  };

  const fetchOEMs = async () => {
    const { data } = await getOEMs();
    const { OEMs } = data;
    if (OEMs && OEMs.length > 0) {
      const availableOEMs = OEMs.map((oem) => {
        const models = oem.models.map((model) => {
          return { label: model, value: model };
        });
        return { brand: { label: oem.name, value: oem.name }, models: models };
      });
      setAvailableOEMs(availableOEMs);
      const availableBrands = availableOEMs.map((oem) => {
        return { label: oem.brand.label, value: oem.brand.value };
      });
      setBrands(availableBrands);
    }
  };

  const fetchInterestData = async (value, typeOfCustomer) => {
    if (!value || !typeOfCustomer) return;
    let data;

    if (interestDataCache.length === 0) {
      const temp = await getInitialValues(value, typeOfCustomer, true, false, false, ""); // default is new cars
      data = temp['data'];
      setInterestDataCache([...interestDataCache, data]);
    }
    else {
      data = interestDataCache.find(
        (item) => item.model === value && item.customer === typeOfCustomer
      );

      if(!data) {
        const temp = await getInitialValues(value, typeOfCustomer, true, false, false, ""); // default is new cars
        data = temp['data'];
        setInterestDataCache([...interestDataCache, data]);
      }
    }

    setInterest(data);
  };

  const setInterest = (value) => {
    const oemValue = getOemValueByMileageAndMonth(
      value.residualValuesOEM,
      leasingCalculatorState.currKm,
      leasingCalculatorState.currMonth
    );

    if (oemValue <= 0) return;

    const maxResidual = oemValue * value.catalogPrice;
    const prePayment = value.reservationFee;

    setLeasingCalculatorState({
      ...leasingCalculatorState,
      catalogPrice: value.catalogPrice,
      modelType: value.model,
      customerType: value.customer,
      nomInterest: value.nomInterest,
      effInterest: calcEffInterest(value.nomInterest),
      prePaymentFrom: value.prePaymentFrom,
      prePaymentTo: value.prePaymentTo,
      reservationFee: value.reservationFee,
      prePayment,
      residualvalueFrom: value.residualvalueFrom,
      residualValuesOEM: value.residualValuesOEM,
      maxResidual: maxResidual,
      currResidual: maxResidual,
        //leasingCalculatorState.currResidual === 0 ? maxResidual : currResidual, // initial value = maxResidual
      modelImageSrc: value.examplaryPicture,
      finalValue: calcFinalValue(
        value.nomInterest,
        leasingCalculatorState.currMonth,
        prePayment,
        value.catalogPrice,
        maxResidual
      ),
      termToggles: value.termToggles, //bla
    });
  };

  const handleOnBrandChange = (selectedBrand) => {
    const availableModels = availableOEMs.filter(
      (oem) => oem.brand.value === selectedBrand
    );
    if (availableModels.length > 0) {
      setModels(availableModels[0].models);
    }

    const newState = {
      ...leasingCalculatorState,
      modelMarke: selectedBrand,
      modelType: "",
    };
    setLeasingCalculatorState(newState);
  };

  const handleOnModelTypeChange = (value) => {
    fetchInterestData(value, leasingCalculatorState.customerType);
  };

  const handleOnCustomerTypeChange = async (value) => {
    // initial state, when user does not select any model type
    if (leasingCalculatorState.modelType === "") {
      setLeasingCalculatorState({
        ...leasingCalculatorState,
        customerType: value,
      });
      return;
    }

    fetchInterestData(leasingCalculatorState.modelType, value);
  };

  const handleOnPriceChange = (value) => {
    const newPrice = value < 0 ? 0 : value;
    const oemValue = getOemValueByMileageAndMonth(
      leasingCalculatorState.residualValuesOEM,
      leasingCalculatorState.currKm,
      leasingCalculatorState.currMonth
    );

    const {
      prePaymentFrom,
      reservationFee,
      prePaymentTo,
      prePayment,
    } = leasingCalculatorState;

    if (oemValue <= 0) return;
    const newMaxResidual = oemValue * newPrice;

    const prePaymentMin =
      prePaymentFrom * newPrice > reservationFee
        ? prePaymentFrom * newPrice
        : reservationFee;
    const prePaymentMax = prePaymentTo * newPrice;
    const newPrePayment =
      prePayment > prePaymentMax
        ? prePaymentMax
        : prePayment < prePaymentMin
        ? prePaymentMin
        : prePayment;

    setLeasingCalculatorState({
      ...leasingCalculatorState,
      catalogPrice: newPrice,
      maxResidual: newMaxResidual,
      currResidual: newMaxResidual,
      prePayment: newPrePayment,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        leasingCalculatorState.currMonth,
        newPrePayment,
        newPrice,
        newMaxResidual
      ),
    });
  };

  const handleOnPrePaymentChange = (value) => {
    const prePaymentMax =
      leasingCalculatorState.prePaymentTo * leasingCalculatorState.catalogPrice;
    const prePaymentMin =
      leasingCalculatorState.prePaymentFrom;

    const prePayment =
      value <= prePaymentMax
        ? value >= prePaymentMin
          ? value
          : prePaymentMin
        : prePaymentMax;

    setLeasingCalculatorState({
      ...leasingCalculatorState,
      prePayment,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        leasingCalculatorState.currMonth,
        Math.round(prePayment),
        leasingCalculatorState.catalogPrice,
        leasingCalculatorState.currResidual
      ),
    });
  };

  const handleOnCurrResidualChange = (value) => {
    const minResidual = leasingCalculatorState.catalogPrice * leasingCalculatorState.residualvalueFrom;
    const currResidual =
      value <= leasingCalculatorState.maxResidual
        ? value >= minResidual
          ? value
          : minResidual
        : leasingCalculatorState.maxResidual;
    setLeasingCalculatorState({
      ...leasingCalculatorState,
      currResidual,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        leasingCalculatorState.currMonth,
        leasingCalculatorState.prePayment,
        leasingCalculatorState.catalogPrice,
        currResidual
      ),
    });
  };

  const handleOnMonthChange = (currMonth) => {
    const oemValue = getOemValueByMileageAndMonth(
      leasingCalculatorState.residualValuesOEM,
      leasingCalculatorState.currKm,
      currMonth
    );
    const oem = getOemByMileage(
      leasingCalculatorState.residualValuesOEM,
      leasingCalculatorState.currKm
    );
    if (oemValue <= 0 || oem === null) return;

    const maxResidual = oemValue * leasingCalculatorState.catalogPrice;
    const currResidual =
      isEqualOrGreater(
        leasingCalculatorState.currResidual,
        leasingCalculatorState.maxResidual
      ) || isEqualOrGreater(leasingCalculatorState.currResidual, maxResidual)
        ? maxResidual
        : leasingCalculatorState.currResidual;

    setLeasingCalculatorState({
      ...leasingCalculatorState,
      currMonth,
      maxResidual,
      currResidual,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        currMonth,
        leasingCalculatorState.prePayment,
        leasingCalculatorState.catalogPrice,
        currResidual
      ),
    });
  };

  const handleOnKMChange = (currKm) => {
    const maxMonth = getMaxMonthOnMonthChange(currKm);
    const currMonth =
      leasingCalculatorState.currMonth > maxMonth
        ? maxMonth
        : leasingCalculatorState.currMonth;

    const oemValue = getOemValueByMileageAndMonth(
      leasingCalculatorState.residualValuesOEM,
      currKm,
      currMonth
    );
    if (oemValue <= 0) return;

    const maxResidual = oemValue * leasingCalculatorState.catalogPrice;
    const currResidual =
      isEqualOrGreater(
        leasingCalculatorState.currResidual,
        leasingCalculatorState.maxResidual
      ) || isEqualOrGreater(leasingCalculatorState.currResidual, maxResidual)
        ? maxResidual
        : leasingCalculatorState.currResidual;

    setLeasingCalculatorState({
      ...leasingCalculatorState,
      maxResidual,
      currResidual,
      currKm,
      currMonth,
      maxMonth,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        currMonth,
        leasingCalculatorState.prePayment,
        leasingCalculatorState.catalogPrice,
        currResidual
      ),
    });
  };

  const {
    catalogPrice,
    maxResidual,
    modelMarke,
    modelType,
    currMonth,
    currKm,
    nomInterest,
    effInterest,
    currResidual,
    prePayment,
    finalValue,
    residualValuesOEM,
    residualvalueFrom,
    prePaymentTo,
    prePaymentFrom,
    modelImageSrc,
    termToggles,
  } = leasingCalculatorState;

  const sendEmail = () => {
    const formattedBody = generateMailBody(
      formatMessage,
      modelType,
      catalogPrice,
      prePayment,
      currMonth,
      currKm,
      currResidual,
      finalValue
    );
    const mailToLink =
      "mailto:support@leaseteq.ch?body=" + encodeURIComponent(formattedBody);
    window.location.href = mailToLink;
  };

  return (
    <div className="layout-root-publicleasing">
      <div
        className={
          isOpenModalCta ? "header-wrapper z-index-1" : "header-wrapper"
        }>
        <Header />
      </div>
      <div className="container-wrapper ">
        <div className="item-wrapp">
          <div
            className={`body-wrapper`}
            style={{
              gridTemplateColumns: `${getGridSystemForBody(width)}`,
            }}>
            <div className="space1"></div>
            <div
              className="body-left-wrapper"
              style={{ height: isCalculatorAvailable ? "100vh" : "80vh" }}>
              <div className="car-holder-wrapper">
                <div className="bg-car-holder">
                  {modelType !== "" ? (
                    modelImageSrc !== "" ? (
                      <Image src={modelImageSrc} alt="car-holder" />
                    ) : (
                      <ImageComponent carModel={modelType} />
                    )
                  ) : (
                    <Image src={CarHolderImgSrc} alt="car-holder" />
                  )}
                  <div className="car-holder-content">
                    <Image
                      src={CarBackgroundImgSrc}
                      alt="car-holder"
                      style={{ paddingRight: "40px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="space2"></div>
            <div className={`body-right-wrapper`} ref={bodyRef}>
              <GeneralInformation
                brands={brands}
                selectedBrand={modelMarke}
                models={models}
                selectedModel={modelType}
                onBrandChange={handleOnBrandChange}
                onModelChange={handleOnModelTypeChange}
                onCustomerTypeChange={handleOnCustomerTypeChange}
                goToPaymentView={() => jumpToView(paymentViewRef)}
              />
              {isCalculatorAvailable && (
                <Fragment>
                  <PaymentInformation
                    maxResidual={maxResidual}
                    residualvalueFrom={residualvalueFrom}
                    price={catalogPrice}
                    onPriceChange={handleOnPriceChange}
                    prePayment={prePayment}
                    onPrePaymentChange={handleOnPrePaymentChange}
                    currResidual={currResidual}
                    onCurrResidualChange={handleOnCurrResidualChange}
                    paymentViewRef={paymentViewRef}
                    goToDurationView={() => jumpToView(durationViewRef)}
                    prePaymentMax={prePaymentTo * catalogPrice}
                    prePaymentMin={prePaymentFrom}
                    showTeslaConfigurator={modelMarke === "Tesla"}
                  />
                  <DurationInformation
                    selectedMonth={currMonth}
                    onMonthChange={handleOnMonthChange}
                    durationViewRef={durationViewRef}
                    goToMileageView={() => jumpToView(mileageViewRef)}
                    currKm={currKm}
                    nomInterest={nomInterest}
                    prePayment={prePayment}
                    price={catalogPrice}
                    currResidual={currResidual}
                    finalValue={finalValue}
                    termToggles={termToggles}
                  />
                  <MileageInformation
                    selectedMileage={currKm}
                    onMileageChange={handleOnKMChange}
                    mileageViewRef={mileageViewRef}
                    nomInterest={nomInterest}
                    prePayment={prePayment}
                    price={catalogPrice}
                    currResidual={currResidual}
                    finalValue={finalValue}
                    currMonth={currMonth}
                    residualValuesOEM={residualValuesOEM}
                    sendEmail={sendEmail}
                    showTeslaConfigurator={modelMarke === "Tesla"}
                  />
                </Fragment>
              )}
            </div>
            <div className="space3"></div>
          </div>
        </div>
      </div>
      {isCalculatorAvailable ? (
        <div>
          <div
            className={isOpenModalCta ? "cta2 z-index-2" : "cta2 is-sticky"}
            ref={ctaRef}>
            <StickyCta
              model={modelType}
              subTitle={formatMessage({
                id: "publicleasing.text.cta-leasingrate",
              })}
              price={catalogPrice}
              leasePrice={finalValue}
              prePayment={prePayment}
              currMonth={currMonth}
              currKm={currKm}
              currency="CHF"
              nomInterest={nomInterest}
              effInterest={effInterest}
              currResidual={currResidual}
              setIsOpenModalCta={setIsOpenModalCta}
              isOpenModalCta={isOpenModalCta}
              sendEmail={sendEmail}
              showTeslaConfigurator={modelMarke === "Tesla"}
            />
          </div>
          <div className="footer2">
            <FooterView refProp={footerRef} />
          </div>
        </div>
      ) : (
        <div className="footer-wrapper">
          <FooterView refProp={footerRef} />
        </div>
      )}
    </div>
  );
}

export default LeasingCalculator;
