import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { postCustomerData, getContractData } from "utils/api";
import { useLeasingFormStep } from "hooks/useLeasingFormStep";
import CalculatorPage from "./Calculator";
import PersonalInformationPage from "./PersonalInformation";
import ProfessionIncomePage from "./ProfessionIncome";
import LivingAndExpensesPage from "./LivingAndExpenses";
import VerificationPage from "./Verification";
import ForwardInformationPage from "./ForwardInformation";
import { useLocale } from "../../context/locale";
import {
  formatContractDataScreen1,
  formatPersonDataScreen2,
  formatFinancialsDataScreen3,
  formatFinancialsDataScreen4,
  formatBeneficial,
  formatProspect
} from "./Helpers/formatter";
import Validate from "./Helpers/Validate";
import { PRIVATE_STEPPER_OPTIONS } from "constant";
import Loading from "screen/Common/Loading";
import { useIntl } from "react-intl";

export default function LeasingFormPrivate() {
  const { locale } = useLocale();
  const { getValues, setValue, trigger, watch } = useFormContext();
  const [loading, setLoading] = useState(false);
  const { formCurrentStep, updateCurrentStep } = useLeasingFormStep();
  const [currentStep, setStep] = useState(formCurrentStep);
  const { formatMessage } = useIntl();

  // Format and submit data
  const handleSubmit = async () => {
    const contract = formatContractDataScreen1(getValues());
    const personValue = { ...getValues(), sprache: locale };
    const person = formatPersonDataScreen2(personValue);
    const beneficial = formatBeneficial(getValues());
    const financials = {...formatFinancialsDataScreen3(getValues()), ...formatFinancialsDataScreen4(getValues())};
    const prospect = formatProspect(getValues());
    setLoading(true);
    try {
      const resp = await postCustomerData(
        person,
        contract,
        financials,
        beneficial,
        prospect
      );
      if (resp.status === 200) {
        setValue("contractID", resp?.data?.contractID);
        if (resp?.data?.contractID) {
          const contractData = await getContractData(resp?.data?.contractID);
          if (contractData?.data && contractData?.status === 200) {
            setValue("contractData", contractData?.data);
            setStep(5);
            setLoading(false);
          } else {
            setLoading(false);
            setValue("isSupport", true);
            setValue("status", contractData?.status);
            setStep(6);
          }
        } else {
          setStep(5);
          setLoading(false);
        }
      } else {
        setValue("isSupport", true);
        setValue("status", resp.status);
        setLoading(false);
        setStep(6);
      }
    } catch (error) {
      setLoading(false);
      setValue("isSupport", true);
      setStep(6);
    }
  };

  const [validPages, setValidPages] = useState(getValues("validPages"));

  // Page validation
  const triggerForm = async (page) => {
    const isValid = await Validate(page, trigger, watch);
    if (!validPages.includes(page) && isValid) {
      setValidPages((val) => val.concat(page));
    } else if (validPages.includes(page) && !isValid) {
      setValidPages((val) => val.filter((item) => item !== page));
    }
    return isValid;
  };

  // Update current step
  useEffect(() => {
    // currentStep()
    updateCurrentStep(currentStep);
  }, [currentStep, updateCurrentStep]);

  // Update valid pages
  useEffect(() => {
    setValue("validPages", validPages);
  }, [validPages]);

  switch (currentStep) {
    case 2:
      return (
        <PersonalInformationPage
          onBackStep={() => setStep(1)}
          onNextStep={() => setStep(3)}
          stepperOptions={{
            ...PRIVATE_STEPPER_OPTIONS,
            currentStep,
            validPages,
            triggerForm,
            onClick: async (step) => {
              triggerForm(2);
              step === 1 ? setStep(1) : setStep(step);
            },
          }}
        />
      );
    case 3:
      return (
        <ProfessionIncomePage
          onBackStep={() => setStep(2)}
          onNextStep={() => setStep(4)}
          stepperOptions={{
            ...PRIVATE_STEPPER_OPTIONS,
            currentStep,
            validPages,
            triggerForm,
            onClick: (step) => {
              triggerForm(3);
              setStep(step);
            },
          }}
        />
      );
    case 4:
      return (
        <>
          {loading ? (
            <Loading message={formatMessage({ id: "expenses.text.loading" })} />
          ) : (
            <LivingAndExpensesPage
              onBackStep={() => setStep(3)}
              onNextStep={handleSubmit}
              stepperOptions={{
                ...PRIVATE_STEPPER_OPTIONS,
                validPages,
                currentStep,
                triggerForm,
                onClick: (step) => {
                  triggerForm(4);
                  step < 4 ? setStep(step) : handleSubmit();
                },
              }}
            />
          )}
        </>
      );
    case 5:
      return (
        <VerificationPage
          onBackStep={() => setStep(4)}
          onNextStep={() => setStep(6)}
          stepperOptions={{
            ...PRIVATE_STEPPER_OPTIONS,
            validPages,
            triggerForm,
            currentStep,
            onClick: setStep,
          }}
        />
      );
    case 6:
      return (
        <ForwardInformationPage
          onBackStep={() => setStep(5)}
          stepperOptions={{
            ...PRIVATE_STEPPER_OPTIONS,
            validPages,
            triggerForm,
            currentStep,
            onClick: setStep,
          }}
        />
      );
    default:
      return (
        <CalculatorPage
          onNextStep={() => setStep(2)}
          stepperOptions={{
            ...PRIVATE_STEPPER_OPTIONS,
            validPages,
            currentStep,
            onClick: setStep,
          }}
        />
      );
  }
}
