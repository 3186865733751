import React from "react";
import { format } from "currency-formatter";
import { useIntl } from "react-intl";
import "./cta.css";
function ShortInfo({
  price,
  leasePrice,
  subTitle,
  nomInterest,
  effInterest,
  currency,
  border = false,
}) {
  const { formatMessage } = useIntl();

  return (
    <div className={`short-info-wrap ${border ? "border-short" : ""}`}>
      <h5 className="font-weight-bold big-title-short-info">
        {leasePrice
          ? format(leasePrice, {
              thousand: "‘",
              code: `${currency}`,
              precision: 2,
            })
          : format(price, { thousand: "‘", code: `${currency}`, precision: 2 })}
        <small className="short-sub-title">
          {leasePrice ? (
            <span>{subTitle}</span>
          ) : (
            ` ${formatMessage({ id: "publicleasing.text.cashprice" })}`
          )}
        </small>
      </h5>
      {nomInterest && effInterest ? (
        <small className="nom-eff-wrapper">
          {`${formatMessage({
            id: "publicleasing.text.nomInterest",
          })} ${format(nomInterest, { decimal: ".", precision: 4,})}% ${formatMessage({
            id: "common.text.pa",
          })} (${formatMessage({
            id: "publicleasing.text.effInterest",
          })}: ${format(effInterest, { decimal: ".", precision: 4,})}% ${formatMessage({
            id: "common.text.pa",
          })})`}
        </small>
      ) : (
        ""
      )}
    </div>
  );
}

ShortInfo.propTypes = {};

export default ShortInfo;
