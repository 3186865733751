import moment from "moment";
import { toNumber } from "utils";

// Data formatter

export const formatDataScreen1 = (formValues) => ({
  laufleistung: toNumber(formValues["laufleistung"]),
  laufzeit: toNumber(formValues["laufzeit"]),
  kaufpreis: toNumber(formValues["carPrice"]),
  anzahlung: toNumber(formValues["anzahlung"], 0),
  leasingrate: toNumber(formValues["leasingrate"]),
  restwert: toNumber(formValues["restwert"], 2),
  model: formValues["model"],
  orderID: formValues["orderID"],
  carOptions: formValues["carOptions"],
  customerTypePrivate: formValues["customerTypePrivate"],
  vehicleTypeNew: formValues["vehicleTypeNew"],
  isInventory: formValues["isInventory"],
  nomInterest: Number(formValues["nomInterest"]),
  effInterest: Number(formValues["effInterest"]),
  prospectIdParam: formValues["prospectId"],
});

export const formatDataScreen2 = (formValues) => ({
  companyName: formValues["companyName"] || "",
  companyStreet: formValues["companyStreet"] || "",
  companyNo: formValues["companyNo"] || "",
  companyZIP: formValues["companyZIP"] || "",
  companyPlace: formValues["companyPlace"] || "",
  companyCountry:
    (formValues["companyCountry"] || {}).value || formValues["country"] || "CH",
  companyCounty:
    (formValues["companyCounty"] || {}).value || formValues["county"] || "",
  companyFounded: toNumber((formValues["companyFounded"] || {}).value),
  companyUID: formValues["companyUID"] || "",
  companyEmployees: toNumber(formValues["companyEmployees"]) || "",
  betreibungen: formValues["betreibungen"] === "Ja",
});

export const formatDataScreen3 = (formValues, locale) => ({
  language: locale,
  remarks: formValues["remarks"],
  salutation: formValues["salutation"] || "",
  firstName: formValues["firstName"] || "",
  lastName: formValues["lastName"] || "",
  birthday: formValues["birthday"]
    ? moment(formValues["birthday"]).format("YYYY-MM-DD")
    : "",
  nationality:
    (formValues["nationality"] || {}).value || formValues["defaultLand"].value,
  street: formValues["street"],
  number: formValues["number"],
  zip: formValues["zip"],
  place: formValues["place"],
  country: (formValues["country"] || {}).value || "CH",
  role: formValues["role"] || "",
  mobile: formValues["mobile"] || "",
  private: formValues["private"] || "",
  business: formValues["business"] || "",
  otherPhone: formValues["otherPhone"] || "",
  telephoneNumber: formValues["telephoneNumber"] || "",
  email: formValues["email"] || "",
  "2ndAuthority": formValues["2ndAuthority"] === "Nein",
  salutation2:
    formValues["2ndAuthority"] === "Nein" ? formValues["salutation2"] : "",
  firstName2:
    formValues["2ndAuthority"] === "Nein" ? formValues["firstName2"] : "",
  lastName2:
    formValues["2ndAuthority"] === "Nein" ? formValues["lastName2"] : "",
  birthday2:
    formValues["2ndAuthority"] === "Nein"
      ? formValues["birthday2"]
        ? moment(formValues["birthday2"]).format("YYYY-MM-DD")
        : ""
      : "",
  nationality2:
    formValues["2ndAuthority"] === "Nein"
      ? (formValues["nationality2"] || {}).value ||
        formValues["defaultLand"].value
      : "",
  street2: formValues["2ndAuthority"] === "Nein" ? formValues["street2"] : "",
  number2: formValues["2ndAuthority"] === "Nein" ? formValues["number2"] : "",
  zip2: formValues["2ndAuthority"] === "Nein" ? formValues["zip2"] : "",
  place2: formValues["2ndAuthority"] === "Nein" ? formValues["place2"] : "",
  country2:
    formValues["2ndAuthority"] === "Nein"
      ? (formValues["country2"] || {}).value || formValues["country"] || "CH"
      : "",
  MDRole2: formValues["2ndAuthority"] === "Nein" ? formValues["MDRole2"] : "",
  email2: formValues["2ndAuthority"] === "Nein" ? formValues["email2"] : "",
  salutationDriver: formValues["salutationDriver"],
  firstNameDriver: formValues["firstNameDriver"],
  lastNameDriver: formValues["lastNameDriver"],
  birthdayDriver: formValues["birthdayDriver"],
  streetDriver: formValues["streetDriver"],
  numberDriver: formValues["numberDriver"],
  zipDriver: formValues["zipDriver"],
  placeDriver: formValues["placeDriver"],
  nationalityDriver:
    (formValues["nationalityDriver"] || {}).value ||
    formValues["defaultLand"].value,
});

export const formatDataScreen4 = (formValues) => ({
  pastTurnover2: toNumber(formValues["pastTurnover2"]),
  pastTurnover1: toNumber(formValues["pastTurnover1"]),
  pastResult2: toNumber(formValues["pastResult2"]),
  pastResult1: toNumber(formValues["pastResult1"]),
  pastEquity2: toNumber(formValues["pastEquity2"]),
  pastEquity1: toNumber(formValues["pastEquity1"]),
  // actualRevenue: toNumber(formValues["actualRevenue"]),
  // monthlyMean: toNumber(formValues["monthlyMean"]),
  existingContracts: toNumber(formValues["existingContracts"]),
  attachablepart:
    formValues["replaceExitContractChecked"] === "Nein"
      ? 0
      : toNumber(formValues["attachablepart"]),
});

export const formatDataBeneficialOwner = (formValues) => ({
  controlHolder: String(formValues["controlHolder"]).toUpperCase(),
  salutationKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["salutationKInh1"],
  firstNameKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["firstNameKInh1"],
  lastNameKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["lastNameKInh1"],
  birthdayKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["birthdayKInh1"]
    ? moment(formValues["birthdayKInh1"]).format("YYYY-MM-DD")
    : "",
  nationalityKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : (formValues["nationalityKInh1"] || {}).value ||
      formValues["defaultLand"].value,
  countryKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : (formValues["countryKInh1"] || {}).value || formValues["country"] || "CH",
  streetKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["streetKInh1"],
  numberKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["numberKInh1"],
  postcodeKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["postcodeKInh1"],
  placeKInh1: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["placeKInh1"],

  salutationKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["salutationKInh2"],
  firstNameKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["firstNameKInh2"],
  lastNameKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["lastNameKInh2"],
  birthdayKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["birthdayKInh2"]
    ? moment(formValues["birthdayKInh2"]).format("YYYY-MM-DD")
    : "",
  nationalityKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : (formValues["nationalityKInh2"] || {}).value ||
      formValues["defaultLand"].value,
  countryKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : (formValues["countryKInh2"] || {}).value || formValues["country"] || "CH",
  streetKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["streetKInh2"],
  numberKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["numberKInh2"],
  postcodeKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["postcodeKInh2"],
  placeKInh2: ["1", "3"].includes(formValues["controlHolder"])
    ? ""
    : formValues["placeKInh2"],
});

export const formatDataProspect = (formValues) => ({
  prospectActive: formValues["prospectActive"],
  prospectID: formValues["prospectID"]
});