import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProspectData } from "../../../utils/api";
import Home from "../../Home";

const ProspectLink = () => {
  let params = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    getProspectData(params.uuid)
      .then(({ data }) => {
        setData(data);
        localStorage.setItem("prospectID", data.prospect.prospectID);
        localStorage.setItem("prospectActive", data.prospect.active);
        localStorage.setItem("prospectManufacturer", data.carData.manufacturer);
        localStorage.setItem("prospectMileage", data.financials.mileage);
        localStorage.setItem("prospectResidualValue", data.financials.residualValue);
        localStorage.setItem("prospectTerm", data.financials.term);
        localStorage.setItem("prospectPrepayment", data.financials.prepayment);
        localStorage.setItem("prospectSalutation", data.customer.salutation);
        localStorage.setItem("prospectFirstName", data.customer.firstName);
        localStorage.setItem("prospectLastName", data.customer.lastName);
      })
      .catch(function (err) {
          console.warn("Something went wrong while retrieving prospect data.", err);
      });
  }, [params]);
  return <Home data={data}></Home>;
};

export default ProspectLink;
