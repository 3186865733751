import React from "react";
import { Col } from "react-bootstrap";
import { RESPONSIVE } from "constant";
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "react-responsive";
import LogoIcon from "../../../assets/icons/LogoIcon.png";

export default function PageHeader() {
  const isLargeSize = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_LARGE });
  return (
    <>
      <Col
        md={12}
        sm={12}
        xs={12}
        className="d-flex p-0 align-items-center mb-2"
        style={{ zIndex: 1 }}
      >
        <div className={`d-flex logo-icon-wrapper mr-2`}>
          <img className="image" src={LogoIcon} alt="LogoIcon" />
        </div>
        <div className="layout-title" style={isLargeSize ? {} : { fontSize: "12pt" }}>
          <FormattedMessage id="calculator.title.pageHeader" />
        </div>
      </Col>
    </>
  );
}
