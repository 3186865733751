import { Route, Switch } from "react-router-dom";
import LeasingForm from ".";
import CustomerSupport from "./Common/CustomerSupport";
import NotFound from "./Common/NotFound";
import ProspectLink from "./Common/ProspectLink/index";
import ThankYou from "./Common/ThankYou";
import Home from "./Home";
import LeasingCalculator from "./LeasingFormPublic/index";

export default function Routes() {
  return (
    <div>
      <Switch>
        <Route path="/:marke/leasing-form/:carType">
          <LeasingForm />
        </Route>
        <Route path="/" exact>
          <Home />
        </Route>

        <Route path="/:marke/thankyou" component={ThankYou} />
        <Route path="/leasingcalculator" component={LeasingCalculator} />
        <Route path="/uuid/:uuid" component={ProspectLink} />
        <Route path="/:marke/customerSupport" component={CustomerSupport} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}
