import React from "react";
import Footer from "../../../components/Footer/Footer";
import "../leasingPublic.css";

const FooterView = ({ refProp }) => {
  return (
    <div ref={refProp}>
      <Footer
        telephone="+41 44 787 01 20"
        email="welcome@leaseteq.ch"
        adresse="Talstrasse 37, 8808 Pfaffikon SZ. Schweiz"
        linked="https://www.linkedin.com/company/leaseteq-ag/"
      />
    </div>
  );
};

export default FooterView;
