import Button from "components/ButtonV2/ButtonV2";
import ButtonDirection from "components/CTA/ButtonDirection";
import { format } from "currency-formatter";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { laufzeitData } from "../defaultData";
import { calcFinalValue, getMaxMonthOnMonthChange } from "../leasing-engine";

const DurationInformation = ({
  selectedMonth,
  onMonthChange,
  currKm,
  nomInterest,
  prePayment,
  price,
  currResidual,
  finalValue,
  durationViewRef,
  goToMileageView,
  termToggles,
}) => {
  const { formatMessage } = useIntl();

  const maxMonth = getMaxMonthOnMonthChange(currKm);
  const getStatus = (value) => {
    if (!termToggles[value]) return "disabled";
    return selectedMonth && selectedMonth === value ? "active" : "idle";
  };

  const getSubtitle = (laufzeitData) => {
    const month = laufzeitData.value;
    if (month === selectedMonth) return "-";

    //if (month > maxMonth)
    if (!termToggles[month])
      return formatMessage({ id: "publicleasing.button.not-available" });

    const res = calcFinalValue(
      nomInterest,
      month,
      prePayment,
      price,
      currResidual
    );
    const diff = res - finalValue;
    return `${diff < 0 ? "-" : "+"} ${format(Math.abs(diff), {
      code: "CHF",
      precision: 0,
    })} ${formatMessage({ id: "publicleasing.button.monthly" })}`;
  };

  return (
    <div className="block-content" ref={durationViewRef}>
      <Row className="mb-4">
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
          <p className="second-title">
            {formatMessage({ id: "publicleasing.title.term" })}
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="button-wrapper">
          {laufzeitData.map((item) => (
            <Button
              title={`${item.value} ${formatMessage({
                id: "publicleasing.button.month",
              })}`}
              subTitle={getSubtitle(item)}
              value={item.value}
              status={getStatus(item.value)}
              size="big"
              onClick={() => onMonthChange(item.value)}
              key={item.value}
            />
          ))}
        </Col>
      </Row>
      <Row className="button-direction-wrapper">
        <ButtonDirection direction="down" onClick={() => goToMileageView()} />
      </Row>
    </div>
  );
};

export default DurationInformation;
