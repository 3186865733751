import React from "react";
import { Col } from "react-bootstrap";
import { RESPONSIVE } from "constant";
import RightBoxItem from "./RightBoxItem";
import { format } from "currency-formatter";
import { FormattedMessage } from "react-intl";
import { useFormContext } from "react-hook-form";
import { useMediaQuery } from "react-responsive";

export default function RightBox({ marke }) {
  const { getValues } = useFormContext();
  const isLargeSize = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_LARGE });
  
  return (
    <Col md={6} sm={12} xs={12} className="d-flex flex-column">
      <RightBoxItem
        title={<FormattedMessage id="calculator.text.marke" />}
        name="marke"
        value={String(marke).toLowerCase() === "tsl" ? "Tesla" : marke}
      />
      <RightBoxItem
        title={<FormattedMessage id="calculator.input.model" />}
        name="model"
      />
      <RightBoxItem
        title={
          isLargeSize ? (
            <>
              <span className="text-nowrap font-weight-bold d-flex justify-content-end ">
                <FormattedMessage id="calculator.input.barkaufpreis" />
              </span>
              <span className="d-flex justify-content-end font-weight-normal">
                <FormattedMessage id="common.text.inchf" />
              </span>
            </>
          ) : (
            <FormattedMessage id="calculator.input.barkaufpreis" />
          )
        }
        name="carPrice"
        value={format(getValues("carPrice"), {
          thousand: "‘",
          precision: 0,
        })}
        isNotInlineTitle={false}
      />
    </Col>
  );
}
